import useTranslation from "next-translate/useTranslation"
import appConfig from "../app-config"
import getStaticSessionProps from "@hornet-web-react/core/middleware/next-props/get-static-session"
import { GetStaticPropsContext } from "next/types"
import { serverPropsOverride } from "../utils/server-props-override"
import Layout from "../components/Layout/Layout"
import PageUnavailable from "../components/Pages/PageUnavailable"

export default function UnavailablePage() {
  const { t } = useTranslation()

  return (
    <Layout
      title={t("quickies:components.pages.page_unavailable.heading")}
      hideTopNavBarMenu={true}
      showBottomNavBar={false}
    >
      <PageUnavailable />
    </Layout>
  )
}

export async function getStaticProps(context: GetStaticPropsContext) {
  return getStaticSessionProps(appConfig, context, serverPropsOverride)
}
