import useTranslation from "next-translate/useTranslation"
import { PrimaryButtonLink } from "@hornet-web-react/core/components/UI/TextButton"
import InfoPage from "./InfoPage"

const i18nKey = `quickies:components.pages.page_unavailable`

export default function PageUnavailable() {
  const { t } = useTranslation()

  return (
    <InfoPage>
      <InfoPage.Logo />
      <InfoPage.Header>{t(`${i18nKey}.heading`)}</InfoPage.Header>
      <InfoPage.SubHeader>{t(`${i18nKey}.text1`)}</InfoPage.SubHeader>

      <InfoPage.CtaWrapper>
        <PrimaryButtonLink
          href={
            "https://docs.google.com/forms/d/e/1FAIpQLSf2o1Q6IBXRIbaIexqxO0cX764ldPTlEH9WK-Ry_jatwv-2uA/viewform"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`${i18nKey}.button_label`)}
        </PrimaryButtonLink>
      </InfoPage.CtaWrapper>
    </InfoPage>
  )
}
